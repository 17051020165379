import moment from "moment";
export default function activityDefault(appName, activityType) {
  let template = {
    "9": {
      activity_id: "",
      merchant_id: "",
      create_time: 0,
      state: 0,
      begin_time: moment().format(),
      end_time: moment().add(7, "days").format(),
      user_defined_task: 0,
      notice_end: 0,
      need_eight_clock_notice: 1,
      need_twenty_clock_notice: 1,
      need_new_friends_notice: 1,
      need_follow_wechat_notice: 1,
      need_game_end_notice: 1,
      need_battle_overstep_notice: 0,
      need_battle_calc_notice: 1,
      need_first_cat_notice: 0,
      need_tomorrow_task_end_notice: 0,
      need_tomorrow_task_finish_notice: 0,
      need_tomorrow_task_will_fail_notice: 0,
      need_dmm_pk_end_notice: 1,
      need_dmm_rewards_full_notice: 1,
      notify_mobile: 0,
      need_coins_notice: 0,
      pk_need_buy: 0,
      world_rank_rewards: {
        1: {
          link: "",
          image: "https://oss.static.rrfenwo.com/jika/获奖页面-奖品图片.png",
          title: "",
          state: 1,
          number: 10,
          sent_number: 0,
        },
        2: {
          link: "",
          image: "https://oss.static.rrfenwo.com/jika/获奖页面-奖品图片.png",
          title: "",
          state: 1,
          number: 15,
          sent_number: 0,
        },
        3: {
          link: "",
          image: "https://oss.static.rrfenwo.com/jika/获奖页面-奖品图片.png",
          title: "",
          state: 1,
          number: 20,
          sent_number: 0,
        },
        "-1": {
          link: "",
          image: "https://oss.static.rrfenwo.com/jika/获奖页面-奖品图片.png",
          title: "",
          state: 0,
          number: 1,
          sent_number: 0,
        },
      },
      max_rank: 2,
      title: appName,
      add_coins: 0,
      rule_link: "攒卡牌拿大奖！\n" +
          "\n" +
          "活动时间：xxxx年xx月xx日至xxxx年xx月xx日【商家填写】\n" +
          "\n" +
          "请大家获得奖品后立即领取！！！\n" +
          "否则活动结束后无法领取！\n" +
          "\n" +
          "奖品：【商家填写】\n" +
          "\n" +
          "一等奖：价值188的裸蛋糕试吃券\n" +
          "二等奖：价值98的起司蛋糕\n" +
          "三等奖：价值38的闪电泡芙\n" +
          "\n" +
          " \n" +
          "\n" +
          "获奖条件：\n" +
          "\n" +
          "游戏中有6张不同卡牌可以被收集。\n" +
          "得奖方式\n" +
          "一等奖需要用户集齐6张不同卡牌可领取\n" +
          "二等奖需要用户集齐5张不同卡牌可领取\n" +
          "三等奖需要用户集齐3张不同卡牌可领取\n" +
          " \n" +
          "领奖方式：\n" +
          "\n" +
          "1、优惠券在您点击领取后直接发放到您的账户。\n" +
          "2、线下奖品领取步骤：\n" +
          "1）需要您在收货页面填写昵称、手机号以及收货地址，并提交\n" +
          "2）此处会跳转到中奖信息-奖品收货信息页面，此处展示了您的收货信息，您所获得的奖品种类，以及客服二维码，截屏此页面\n" +
          "3）联系我们的【微信客服：XXXX】，出示中奖截图，进行等级发奖\n" +
          "\n" +
          "活动规则：\n" +
          "\n" +
          "1、进入活动点击抽卡，开始抽卡；\n" +
          "2、集齐不同种类卡牌\n" +
          "3、集齐指定数量不同种类卡牌后可以在奖品免费领进行奖品领取和兑换\n" +
          "\n" +
          "每个奖品用户仅限领取一次。\n" +
          "\n" +
          "【此处商家可补充用户中奖次数限制。目前游戏不设置中奖次数限制，需商家设置优惠券和人工发奖时进行控制。】\n" +
          "\n" +
          "注意：\n" +
          "\n" +
          "如用户使用不正当方式进行游戏刷分刷奖品等作弊行为，商家有权清除其数据和分数，以及屏蔽该用户id，不向其发放对应奖品。已经作弊刷走一定奖品的用户，商家有权对其追究对应的法律责任，并追回对应的奖品和相关经济损失。\n" +
          "\n" +
          "此活动解释权归xx商家【商家填写】",
      default_power: 3,
      activity_type: 9,
      banners: [
        {
          link: "",
          image: "https://oss.static.rrfenwo.com/admin/statics/duobaoqibing/duobaoqibing/reward.png",
        },
      ],
      cards: [
        {
          id: 1,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "人间锦鲤",
          image: "https://oss.static.rrfenwo.com/jika/人间锦鲤-卡面.png",
          number: 10,
        },
        {
          id: 2,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "一夜暴富",
          image: "https://oss.static.rrfenwo.com/jika/一夜暴富.png",
          number: 15,
        },
        {
          id: 3,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "笑口常开",
          image: "https://oss.static.rrfenwo.com/jika/笑口常开.png",
          number: 15,
        },
        {
          id: 4,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "福气满满",
          image: "https://oss.static.rrfenwo.com/jika/福气满满.png",
          number: 20,
        },
        {
          id: 5,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "健康平安",
          image: "https://oss.static.rrfenwo.com/jika/健康平安.png",
          number: 20,
        },
        {
          id: 6,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "加薪不加班",
          image: "https://oss.static.rrfenwo.com/jika/加薪不加班.png",
          number: 20,
        },
        {
          id: 99,
          number: 999999,
          link: "",
          coffee_id: 1,
          product_id: "",
          title: "卡牌背面",
          image: "https://oss.static.rrfenwo.com/jika/卡背.png",
        },
      ],
      shares: [
        {
          title: "福气满满，分享给你一起领大礼",
          description: "宠粉福利",
          image: "https://oss.static.rrfenwo.com/jika/微信小卡牌.png",
        },
      ],
      shares_new: {
        share_type: 2,
        items: {
          1: {
            title: "@你，大奖近在咫尺就差你的一臂之力",
            image: "https://oss.static.rrfenwo.com/jika/微信小卡牌.png",
            link: "",
          },
          2: {
            title: "福气满满，分享给你一起领大礼",
            image: "https://oss.static.rrfenwo.com/jika/tigershare.png",
            link: "",
          },
          3: {
            title: "",
            image: "https://oss.static.rrfenwo.com/jika/微信小卡牌2.png",
            link: "",
          },
        },
      },
      power_task: [
        {
          link: "",
          image: "https://oss.static.rrfenwo.com/admin/statics/funiu/%E9%93%B6%E5%B8%81%E4%BB%BB%E5%8A%A1.png",
        },
        {
          link: "",
          image: "https://oss.static.rrfenwo.com/admin/statics/funiu/%E9%93%B6%E5%B8%81%E4%BB%BB%E5%8A%A1.png",
        },
      ],
      qrcode: "https://oss.static.rrfenwo.com/jika/二维码.png",
      exchange_ratio: 10,
      coins: 0,
      total_coins: 0,
      big_reward_number: 0,
      sunshine_reward_number: 0,
      need_exchange_coins: "0",
      sunshine_reward_setting: 0,
      logo: "https://oss.static.rrfenwo.com/jika/%E9%9B%86%E5%A5%BD%E8%BF%90%20%E5%85%91%E5%A4%A7%E5%A5%96.png",
      brand_name: "这是品牌名称",
      activity_jika: {
        sent_one: 0,
        sent_two: 0,
        sent_three: 0,
        sent_four: 0,
        sent_five: 0,
        sent_six: 0,
      }
    },
  };
  return template[activityType];
}
